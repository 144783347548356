.carousel__dot-group {
	padding-top: 5px;
}

.carousel__inner-slide {
	max-height: 62vh;
}

.pse-powerbi-mainpage {
	height: 80%;
}
