html {
	overflow: scroll;
	overflow-x: hidden;
}

::-webkit-scrollbar {
	width: 0;
	background: transparent;
}

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Ubuntu", "Segoe UI",
		"Roboto", "Oxygen", "Cantarell", "Fira Sans", "Droid Sans",
		"Helvetica Neue", sans-serif;
	background-color: #121212;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

.focusRing___1airF.carousel__slide-focus-ring {
	outline: none !important;
}
