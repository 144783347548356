.hide-map {
	visibility: hidden;
}

.loading-map-container {
	position: absolute;
	z-index: 10;
	height: 62vh;
	width: 65%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.map-container {
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	height: 62vh;
	position: relative;
}

.map-filter-button-container {
	z-index: 10;
	position: absolute;
	bottom: 0;
	right: 0;
	margin: 10px;
}

.mapboxgl-canvas {
	height: 100%;
	width: 100%;
}

.sidebarStyle {
	display: inline-block;
	position: absolute;
	top: 0;
	left: 0;
	margin: 12px;
	background-color: #404040;
	color: #ffffff;
	z-index: 1 !important;
	padding: 6px;
	font-weight: bold;
}

.calculation-box {
	height: 150px;
	width: 150px;
	position: absolute;
	bottom: 40px;
	left: 10px;
	background-color: rgba(255, 255, 255, 0.9);
	padding: 15px;
	text-align: center;
}

.mapboxgl-ctrl-attrib {
	visibility: hidden;
}

p {
	font-family: "Open Sans";
	margin: 0;
	font-size: 13px;
}

#menu {
	/* To Remove */
	/* display: none; */

	background: #fff;
	position: absolute;
	z-index: 1;
	top: 50px;
	left: 10px;
	border-radius: 3px;
	width: 120px;
	border: 1px solid rgba(0, 0, 0, 0.4);
	font-family: "Open Sans", sans-serif;
}

#menu a {
	font-size: 13px;
	color: #404040;
	display: block;
	margin: 0;
	padding: 0;
	padding: 10px;
	text-decoration: none;
	border-bottom: 1px solid rgba(0, 0, 0, 0.25);
	text-align: center;
}

#menu a:last-child {
	border: none;
}

#menu a:hover {
	background-color: #f8f8f8;
	color: #404040;
}

#menu a.active {
	background-color: #3887be;
	color: #ffffff;
}

#menu a.active:hover {
	background: #3074a4;
}
