.scenarioChartNode {
	color: black;
	border-radius: 4px;
	background-color: #ffe8af;

	&:hover {
		cursor: pointer;

		.delete-scenario-icon {
			visibility: visible;
		}
	}
}

.org-chart-container {
	min-width: 160px;
	text-align: left;
	padding: 8px !important;
}

.org-chart-name {
	font-weight: 600;
	font-size: 14px;
}

.org-chart-username {
	font-size: 12px;
}

.scenarioChartNodeSelected {
	background: #ffad38;
	color: #2e2e2e;
}

.scenarioChart {
	margin: 0;
}

.scenarioChartNode .scenarioName {
	color: #ffad38;
}

.scenarioChartNodeSelected .scenarioName {
	color: #000000;
}

.scenarioChart .google-visualization-orgchart-linebottom {
	border-bottom: 1px solid #ffad38;
}

.scenarioChart .google-visualization-orgchart-lineleft {
	border-left: 1px solid #ffad38;
}

.scenarioChart .google-visualization-orgchart-lineright {
	border-right: 1px solid #ffad38;
}

.scenarioChart .google-visualization-orgchart-linetop {
	border-top: 1px solid #ffad38;
}

.google-visualization-orgchart-table {
	margin: 0;
}

.delete-scenario-container {
	display: flex;
	justify-content: flex-end;
	width: 100%;
}

.delete-scenario-icon {
	height: 20px;
	visibility: hidden;
}
